import { navigate } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layouts'

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <Helmet>
                  <title>Pagina niet gevonden | Employes</title>
                  <meta name="description" content="Deze pagna bestaat helaas niet." />
                  <meta itemprop="name" content="Pagina niet gevonden | Employes" />
                  <meta itemprop="description" content="Deze pagna bestaat helaas niet." />
                  <meta name="robots" content="noindex,follow" />
                </Helmet>
                <section className="overview padding-xxl">
                    <div className="container-md">
                        <div className="grid center text-center yg">
                            <div className="col-12 margin-md-bottom">
                                <p className="eyebrow">404</p>
                                <h3>Deze pagina is niet gevonden</h3>
                            </div>
                            <div className="col-12 margin-md-bottom">
                                <img src="https://i.pinimg.com/originals/84/53/6e/84536ea88ad82992afb6e879bd7616c2.gif" />
                            </div>
                            <div className="col-12 margin-xl-bottom">
                                <a
                                    className="btn primary"
                                    rel="nofollow"
                                    onClick={() => navigate(-1)}
                                >
                                    Terug naar vorige pagina
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default NotFoundPage
